<template>
  <div class="w-100 h-100 overflow-auto">
    <b-container
      fluid="xl"
    >
      <div class="d-flex justify-content-between align-items-center mt-4">
        <div class="h3">
          Список ролей
        </div>

        <!--        <b-button-->
        <!--          variant="primary"-->
        <!--          class="ml-2"-->
        <!--          size="sm"-->
        <!--          @click="() => openRoleEditModal()"-->
        <!--        >-->
        <!--          Добавить новую роль-->
        <!--        </b-button>-->
      </div>

      <div class="my-2">
        <div
          v-if="startingLoading"
          class="d-flex ml-auto w-75"
        >
          <b-skeleton
            type="input"
            class="w-50"
          />
          <b-skeleton
            type="input"
            class="w-50 ml-2"
          />
        </div>

        <div
          v-else
          class="d-flex w-75 ml-auto"
        >
          <v-select
            v-model="selectedPageId"
            :reduce="clinic => clinic.id"
            :options="filteredPageList"
            placeholder="Выберите страничку"
            title="Выберите страничку, что бы узнать у каких ролей она присутствует"
            label="name"
            :clearable="true"
            class="bg-white w-50"
            @input="savePageInLocalstorage"
          />
          <v-select
            v-model="comparePageId"
            :reduce="clinic => clinic.id"
            :options="filteredPageList"
            placeholder="Выберите страничку для сравнения"
            title="Выберите страничку для сравнения"
            label="name"
            :clearable="true"
            class="bg-white w-50 ml-2"
            @input="saveComparePageInLocalstorage"
          />
        </div>
      </div>

      <div
        v-if="!totalCount && !isLoading"
        class="px-3"
      >
        Ничего не найдено
      </div>

      <template v-if="isLoading">
        <preloader style="margin-top: 20px" />
      </template>

      <b-table
        v-if="totalCount && !isLoading"
        class="bg-white"
        bordered
        :fields="fields"
        :items="filteredRoles"
      >
        <template #cell(Фича)="row">
          <b-badge
            v-if="checkPage(row.item.pageIds)"
            class="p-2"
            pill
            variant="success"
          >
            Есть
          </b-badge>
          <b-badge
            v-else
            class="p-2"
            pill
            variant="danger"
          >
            Нет
          </b-badge>
        </template>
        <template #cell(Действия)="row">
          <b-button
            size="sm"
            variant="primary"
            @click="openRoleEditModal(row.item.id)"
          >
            Редактировать
          </b-button>
        </template>
      </b-table>
    </b-container>
  </div>
</template>

<script>
import Preloader from '@/components/Preloader';

export default {
  name: 'Roles',
  page: {
    title: 'CRM Doctis - Список ролей',
  },
  components: {
    Preloader,
  },
  data() {
    return {
      pageList: [],
      selectedPageId: null,
      comparePageId: null,
      startingLoading: false,
    };
  },
  computed: {
    roles() {
      return this.$store.state.Roles.roles;
    },
    fields() {
      const fields = [
        {
          key: 'id',
          label: 'ID',
        },
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'Действия',
          label: '',
          class: 'td-button',
        },
      ];

      if (this.selectedPageId && this.comparePageId) {
        fields.splice(2, 0, {
          key: 'Фича',
          label: 'Страница/фича',
        });
      }

      return fields;
    },
    filteredRoles() {
      const roles = JSON.parse(JSON.stringify(this.roles));
      roles.sort((a, b) => a.name - b.name);

      if (this.selectedPageId !== null) {
        return roles.filter((item) => item.pageIds.includes(this.selectedPageId));
      }

      return roles;
    },
    filteredPageList() {
      return this.pageList.map((item) => {
        console.log(1);
        return {
          ...item,
          name: `${item.name} ${item.url}`,
        };
      });
    },
    totalCount() {
      return this.roles.length;
    },
    isLoading: {
      get() {
        return this.$store.state.Roles.isRolesLoading;
      },
      set(newVal) {
        this.$store.commit(this.$types.ROLES_LOADING_SET, newVal);
      },
    },
  },
  created() {
    this.selectedPageId = Number(localStorage.getItem('clinics-page-id')) || null;
    this.comparePageId = Number(localStorage.getItem('clinics-compare-page-id')) || null;
  },
  async mounted() {
    this.startingLoading = true;

    try {
      await this.rolesFetch();
      this.pageList = (await this.$store.dispatch(this.$types.PAGES_FETCH)).sort((a, b) => a.index - b.index);
    } catch (e) {
      console.error(e);
    } finally {
      this.startingLoading = false;
    }
  },
  beforeDestroy() {
    this.$store.commit(this.$types.ROLES_SET, []);
    this.isLoading = false;
  },
  methods: {
    async rolesFetch() {
      this.isLoading = true;
      try {
        const roles = await this.$store.dispatch(this.$types.ROLES_FETCH);
        this.$store.commit(this.$types.ROLES_SET, roles);
      } catch (e) {
        console.warn(e);
      } finally {
        this.isLoading = false;
      }
    },
    savePageInLocalstorage() {
      localStorage.setItem('clinics-page-id', JSON.stringify(this.selectedPageId));
    },
    saveComparePageInLocalstorage() {
      localStorage.setItem('clinics-compare-page-id', JSON.stringify(this.comparePageId));
    },
    openRoleEditModal(roleId) {
      this.$store.commit(this.$types.OPEN_MODAL, { name: 'RoleEditModal', props: { roleId } });
    },
    openNewRoleModal() {
      this.$store.commit(this.$types.OPEN_MODAL, { name: 'RoleEditModal' });
    },
    checkPage(pages) {
      return pages.some((item) => item === this.comparePageId);
    },
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep.bg-white .td-button {
    width: 100px;
  }
</style>
